import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Автоматизация",
			desktopTitle: "Программа автоматизации для пекарни в облаке",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Автоматизация пекарни с минимальным набором оборудования: понадобится
					только планшет и&nbsp;устройства для приёма оплат картами, печати
					чеков и доступа в интернет. Просто купи оборудование, настрой
					онлайн-кассу, установи приложение на терминал и работай!
				</p>
			),
			detailLink: pagesLinks.index,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/bakery-reports-1.png"
					alt="программа управления пекарни"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Отчёты",
			desktopTitle: "Быстрые отчёты и гибкие настройки",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Вся аналитика, справочники, настройки хранятся в&nbsp;персональном
					облаке. К системе управления пекарней легко подключать новые точки —
					облако обеспечит единое пространство для контроля за всем бизнесом и
					каждой пекарней по отдельности. Добавляй новые заведения сети, и вся
					нужная номенклатура и техкарты перенесутся автоматически.
				</p>
			),
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/bakery-reports-2.png"
					alt="система автоматизации пекарни"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
