import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import implementationStyles from "../common/implementation-feature/styles.module.scss"

import { pagesLinks } from "../../common/links"

export default [
	{
		title: "Поддержка 54-ФЗ и кассовый терминал с подсказками для сотрудников",
		description: (
			<>
				<p>
					Онлайн-касса — часть системы автоматизации пекарни, которая помогает
					сотрудникам продавать и работать с&nbsp;лояльностью гостей. Кассиры не
					забудут про действующие акции и позиции, которые можно предложить к
					заказу, тем самым увеличив средний чек.
				</p>
				<p data-mt="small">
					Система управления пекарней совместима с кассами, соответствующими требованиям 54-ФЗ.
					Можно принимать любые типы оплат, включая расчёт бонусными баллами.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image1}
				src="./assets/bakery-feature-1.png"
				alt="кассовый терминал для пекарни"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature1,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.terminal.href,
	},
	{
		title: "Удобная CRM-система для пекарни",
		description: (
			<div className={styles.smallDescription}>
				<p>
					Запускай акции на нужные позиции выпечки и&nbsp;десертов, настраивай
					предложения на комбо-наборы и скидки по времени. Используя возможности
					CRM для пекарни и мобильного приложения для гостей, отправляй
					персональные предложения: сегментируй аудиторию по чекам и настроий
					push-уведомления.
				</p>
				<p>
					Автоматизация пекарни выводит программы лояльности на новый уровень.
					Отчёты покажут, какие акции действительно эффективны и приносят прибыль, как гости
					накапливают и расходуют свои бонусы.
				</p>
			</div>
		),

		img: (
			<StaticImage
				className={styles.image2}
				src="./assets/bakery-feature-2.png"
				alt="crm для пекарни"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature2,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.marketing.href,
	},
	{
		title: "Всё для складского учёта",
		description: (
			<>
				<p>
					Реши все вопросы складского учёта, автоматизируя пекарню с&nbsp;Quick
					Resto. Создавай неограниченное количество позиций и техкарт, а система
					сама рассчитает КБЖУ для каждого продукта и напитка. Следи онлайн за
					наличием ингредиентов на складе и готовой продукции в точках
					реализации.
				</p>
				<p>
					Проводи инвентаризацию без бумажных документов прямо с&nbsp;кассового
					терминала — добавь нужные позиции в&nbsp;акт и&nbsp;останется только
					пересчитать продукты на&nbsp;складе. Программа для автоматизации
					пекарни-кондитерской не позволит сотрудникам корректировать результаты
					подсчёта «в свою пользу» — они просто не будут видеть количество
					продуктов в&nbsp;системе.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image3}
				src="./assets/bakery-feature-3.png"
				alt="складской учет в пекарне"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,

		className: styles.feature3,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.warehouse.href,
	},
	{
		title: "Электронное меню для пекарни",
		description: (
			<>
			<p>
				За один день запустим мобильный сайт с меню для пекарни. Гости будут
				попадать на него по QR-коду, который можно разместить у кассы, на входной
				двери, столиках или в&nbsp;уголке потребителя. Добавь ссылку на
				электронное меню в&nbsp;соцсети, карты Яндекса, Google и 2ГИС, чтобы
				захватывать больше новых посетителей.
			</p>
				<p>
					Электронное меню полностью заменяет бумажную версию, при этом не
					теряет привлекательный вид со временем. Не нужно заказывать в
					типографии новый макет и тратиться на печать, если решишь ввести в
					ассортимент новинки. Достаточно изменить позиции в бэк-офисе.
					Меняй меню в пару кликов с автоматизацией пекарни-кондитерской.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image4}
				src="./assets/bakery-feature-4.png"
				alt="электронное меню для пекарни"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature4,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.wlShop.href,
	},
	{
		title: "Внедрение системы автоматизации пекарни под ключ",
		description: (
			<>
				<p>От первой продажи до масштабирования.</p>
				<p>Quick Resto даст все инструменты:</p>
				<ul>
					<li>Приложения для управления и продаж</li>
					<li>Отчёты по продажам, блюдам, сотрудникам</li>
					<li>Складской учёт и технологические карты</li>
					<li>CRM-система и аналитика</li>
					<li>Сервис: установка, настройка, обучение</li>
					<li>Круглосуточная техподдержка</li>
				</ul>
			</>
		),

		img: (
			<StaticImage
				className={implementationStyles.image}
				src="../common/implementation-feature/implementation-feature.png"
				alt="Алексей Гаврилов"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: implementationStyles.feature,
		theme: "white",

		backdrop: (
			<div className={implementationStyles.featureTopRightTrapezeBackdrop} />
		),
	},
]
