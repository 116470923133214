import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Аналитика",
			desktopTitle: "Онлайн-система управления пекарней",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Проверяй необходимые отчёты онлайн: аналитика по выручке и среднему чеку за любой период,
					АВС-анализ прибыльных и убыточных позиций в меню, продажи каждого кассира, фудкост. Все важные показатели
					работы заведения доступны в системе автоматизации пекарни. Как идут дела в заведении, подскажут наглядные графики и диаграммы.
				</p>
			),
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/bakery-analytics-1.png"
					alt="программа автоматизации пекарни"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Уведомления",
			desktopTitle: "Первое, что нужно включить",
			desktopContent: (
				<>
				<p>
					Автоматизация пекарни — защита от махинаций персонала. Настрой
					уведомления, чтобы получать на почту и смартфон сообщения о
					применяемых скидках, отменах чеков и других «опасных» операциях на
					кассе.
				</p>
				<p>
					Следи за наличными в кассе, не посещая заведение. Сотрудники верно закрывают смены и сдают наличные между сменами.
				</p>
				</>
			),
			detailLink: {
				href: pagesLinks.notifications.href,
			},
			mediaContent: (
				<div className={mediaContent.notifiesMedia}>
					<StaticImage
						className={mediaContent.notifiesMedia__phone}
						src="./assets/bakery-analytics-2-phone.png"
						alt="программа для пекарни"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>

					<StaticImage
						className={mediaContent.notifiesMedia__notifies}
						src="./assets/bakery-analytics-2-notifies.png"
						alt="программа для пекарни"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</div>
			),
		},
	],
}
